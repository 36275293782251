<template>
  <div v-if="isReady" class="req-temp-container onboard-review-page mt-10">
    <div class="bread-marz">
      <router-link v-if="!showEditButton" :to="{ name: 'SubmissionApplication', params: { introducerRequestedId: onboardDetail.onboardingRequestId },}">
      <feather-icon icon="ChevronLeftIcon" class="cursor-pointer w-5 h-5 mx-2" style="vertical-align: middle; color:#757575"/>Back
      </router-link>
    </div>
    <h2 class="font-medium text-xl md:text-2xl lg:text-3xl md:mt-0 mb-6 md:mb-8 lg:mb-10" v-if="showEditButton">Onboarding form review</h2>
    <!-- onboardDetail -->
    <vs-row id="profile-page" class="mt-5">
      <!-- Business Information -->
      <vx-card class="accordin-card onboard">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <!-- open -->
            <vs-collapse-item open ref="businessInfoHeight">
              <div slot="header">
                <div class="flex flex-row items-center">
                  <img class="icon-control" :src="edit" alt="home" />
                  <h3 class="pl-4 fs-control font-normal">Business information</h3>
                </div>
              </div>

              <vs-row>
                <!-- General Information -->
                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.0775 1.07733C11.4029 0.751893 11.9306 0.751893 12.256 1.07733L15.5893 4.41066C15.9148 4.7361 15.9148 5.26374 15.5893 5.58917L6.42267 14.7558C6.26639 14.9121 6.05443 14.9999 5.83342 14.9999H2.50008C2.03984 14.9999 1.66675 14.6268 1.66675 14.1666V10.8333C1.66675 10.6122 1.75455 10.4003 1.91083 10.244L11.0775 1.07733ZM3.33341 11.1784V13.3333H5.48824L13.8216 4.99992L11.6667 2.8451L3.33341 11.1784Z"
                        fill="#BDBDBD"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.66675 18.3333C1.66675 17.8731 2.03984 17.5 2.50008 17.5H17.5001C17.9603 17.5 18.3334 17.8731 18.3334 18.3333C18.3334 18.7936 17.9603 19.1667 17.5001 19.1667H2.50008C2.03984 19.1667 1.66675 18.7936 1.66675 18.3333Z"
                        fill="#BDBDBD"
                      />
                    </svg>

                    <h4 class="lg:text-xl md:text-lg text-sm mb-0 font-normal ml-3">General information</h4>
                  </div>
                  <hr class="line-hr mb-8" />
                  <GeneralInformation :generalInfo="onboardDetail.businessInfo" :userId="onboardDetail.onboardingRequestId" :showEditButton="showEditButton" />
                </div>
              </vs-row>

              <vs-row>
                <!-- Contact Details -->
                <div class="w-full mb-8">
                  <div class="flex mb-2 items-center">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.0775 1.07733C11.4029 0.751893 11.9306 0.751893 12.256 1.07733L15.5893 4.41066C15.9148 4.7361 15.9148 5.26374 15.5893 5.58917L6.42267 14.7558C6.26639 14.9121 6.05443 14.9999 5.83342 14.9999H2.50008C2.03984 14.9999 1.66675 14.6268 1.66675 14.1666V10.8333C1.66675 10.6122 1.75455 10.4003 1.91083 10.244L11.0775 1.07733ZM3.33341 11.1784V13.3333H5.48824L13.8216 4.99992L11.6667 2.8451L3.33341 11.1784Z"
                        fill="#BDBDBD"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.66675 18.3333C1.66675 17.8731 2.03984 17.5 2.50008 17.5H17.5001C17.9603 17.5 18.3334 17.8731 18.3334 18.3333C18.3334 18.7936 17.9603 19.1667 17.5001 19.1667H2.50008C2.03984 19.1667 1.66675 18.7936 1.66675 18.3333Z"
                        fill="#BDBDBD"
                      />
                    </svg>

                    <h4 class="lg:text-xl md:text-lg text-sm mb-0 font-normal ml-3">Contact details</h4>
                  </div>
                  <hr class="line-hr mb-8" />
                  <div class="onboarding-form">
                    <ContactDetails :contactDetails="onboardDetail.contactDetails" :userId="onboardDetail.onboardingRequestId" :showEditButton="showEditButton" />
                  </div>
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <vx-card class="accordin-card onboard mt-6" v-if="(onboardingCustomFieldsArray && onboardingCustomFieldsArray.length > 0) || (onboardDetail && onboardDetail.onboardingRequest && onboardDetail.onboardingRequest.creditLimit && onboardDetail.onboardingRequest.creditLimit > 0)">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <!-- open -->
            <vs-collapse-item open ref="businessInfoHeight">
              <div slot="header">
                <div class="flex flex-row items-center">
                  <img class="icon-control" :src="edit" alt="home" />
                  <h3 class="pl-4 fs-control font-normal">Business profile</h3>
                </div>
              </div>

              <vs-row>
                <div class="w-full mb-8">
                  <div class="onboarding-form" v-if="onboardDetail && onboardDetail.onboardingRequest && onboardDetail.onboardingRequest.creditLimit && onboardDetail.onboardingRequest.creditLimit > 0">
                    <div class="flex mt-2 flex-wrap">
                      <div class="input-grp">
                        <label class="vs-input--label pl-0 review-label w-full">Pre-assigned credit limit</label>
                        <p class="textBlack">{{ `$ ${addZeroes(onboardDetail.onboardingRequest.creditLimit)}` }}</p>
                      </div>
                    </div>
                  </div>
                  <BusinessProfile
                    v-if="onboardingCustomFieldsArray && onboardingCustomFieldsArray.length > 0"
                    :businessProfile="onboardDetail.businessProfile"
                    :onboardingCustomFields="onboardingCustomFieldsArray"
                    :userId="onboardDetail.onboardingRequestId"
                    :showEditButton="showEditButton"
                    :message="onboardDetail.onboardingRequest && onboardDetail.onboardingRequest.onboardingProfileMessage ? onboardDetail.onboardingRequest.onboardingProfileMessage : null"
                  />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Business ownership -->
        <vx-card class="accordin-card mt-6" id="businessOwnershipArea">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="businessOwnershipCollapse">
                <div slot="header">
                  <div class="flex flex-row items-center">
                    <img class="icon-control" :src="bank" alt="filetext" />
                    <h3 class="pl-4 fs-control">Business ownership</h3>
                  </div>
                </div>
                <vs-row>
                  <div class="w-full">
                    <BusinessOwnership ref="businessOwnership" :entityList="entityList" :userId="onboardDetail.onboardingRequestId" :businessOwnershipCustomFields="businessOwnershipCustomFields" :showEditButton="showEditButton" />
                  </div>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

      <!-- Bank Account -->
      <vx-card class="accordin-card onboard mt-6">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item open ref="bankAccountCollapse">
              <div slot="header">
                <div class="flex flex-row items-center">
                  <img class="icon-control" :src="bank" alt="bank" />
                  <h3 class="pl-4 fs-control font-normal">Bank accounts</h3>
                </div>
              </div>
              <vs-row>
                <div class="w-full">
                  <BankAccounts :bankAccount="onboardDetail.bankAccount" :userId="onboardDetail.onboardingRequestId" :showEditButton="showEditButton" :entityList="onboardDetail.businessOwnership" />
                </div>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <!-- Product Selection -->
      <vx-card class="accordin-card onboard mt-6">
        <div class="vx-card__body bsv p-0">
          <vs-collapse accordion>
            <vs-collapse-item open ref="productSelectionCollapse">
              <div slot="header">
                <div style="width: 40%" class="flex flex-row">
                  <feather-icon icon="CodepenIcon" svgClasses="iconColor" />
                  <h3 class="text-left card-title pl-4 fs-control font-normal">Product selection</h3>
                </div>
              </div>

              <div>
                <ProductSelection :banks="onboardDetail.bankAccount" :product="onboardDetail.productSelection" :showEditButton="showEditButton" :userId="onboardDetail.onboardingRequestId"></ProductSelection>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </vx-card>

      <div class="mt-10 flex w-full justify-end">
        <vs-button flat size="large" v-round class="ml-8 only-border-btn" @click="goBack()"> Back </vs-button>
        <vs-button v-if="['review', 'terms'].includes(this.currentStep)" flat size="large" v-round class="ml-8" @click="goToTerms()" :disabled="!showEditButton" v-show="showEditButton">Next</vs-button>
      </div>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GeneralInformation from "./businessInformation/GeneralInformation.vue";
import ContactDetails from "./businessInformation/ContactDetails.vue";
import BusinessProfile from "./businessProfile/BusinessProfile.vue";
import BankAccounts from "./bankAccount/bankAccounts.vue";
import BusinessOwnership from "./businessOwnership/BusinessOwnership.vue";
import ProductSelection from "./product/ProductSelection.vue";
import S3FileGenerator from "../components/S3FileGenerator";
import { Edit2Icon, HomeIcon } from "vue-feather-icons";
const edit = require("@/assets/images/edit.svg");
const bank = require("@/assets/images/bank-blue.png");
const filetext = require("@/assets/images/file-text.png");
const product = require("@/assets/images/product.png");
export default {
  name: "index",
  components: {
    Edit2Icon,
    HomeIcon,
    GeneralInformation,
    ContactDetails,
    BusinessProfile,
    BankAccounts,
    BusinessOwnership,
    ProductSelection,
    S3FileGenerator,
  },
  data() {
    return {
      onboardDetail: {},
      userId: "",
      id: "",
      showEditButton: true,
      isReady: false,
      edit: edit,
      bank: bank,
      filetext: filetext,
      product: product,
      onboardingCustomFieldsArray: [],
      entity: [],
      businessOwnershipCustomFields: [],
      currentStep: ""
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },

  methods: {
    ...mapActions("onboardMerchant", [
      "fetchOnboardDetailWithId",
      "submitApplication",
      "updateApplication",
    ]),

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },

    async getDetail() {
      await this.fetchOnboardDetailWithId(this.id)
        .then((result) => {
          this.onboardDetail = result.data.data;
          this.businessOwnershipCustomFields = result.data.data.onboardingRequest.businessOwnershipFields;
          this.isReady = true;
          this.userId = result.data.data._id;
          if (this.onboardDetail.onboardingCustomFields && this.onboardDetail.onboardingCustomFields.length > 0) {
            this.onboardingCustomFieldsArray= this.onboardDetail.onboardingCustomFields;
          }

          this.currentStep = this.onboardDetail.currentStep;

          if (this.onboardDetail.businessOwnership && this.onboardDetail.businessOwnership.length > 0) {
            this.entityList= this.onboardDetail.businessOwnership
          } else {
            this.entityList.push({
              entityType:"",
              companyName:"",
              abn: "",
              acn: "",
              fullName:"",
              emailAddress:"",
              mobileNumber:"",
              registeredAddress: {
                manualMode:false,
                appartmentDetail:"",
                streetAddress:"",
                suburb: "",
                state:"",
                postCode: "",
              },
              isSameAsRegisteredAddress: false,
              tradingAddress: {
                manualMode:false,
                appartmentDetail:"",
                streetAddress:"",
                suburb: "",
                state:"",
                postCode: "",
              },
              isEntityActAsTrust:false,
              trustName:"",
              trustAbn: "",
              trustDeedFiles:"",
              beneficialOwners: [],
              agentLicenseNumber:""
            });
          }

          if (["Approved", "Completed", "Awaiting approval", "Resubmitted"].includes(result.data.data.status)) {
            this.showEditButton = false;
          }

          const d = setTimeout(() => {
            this.$refs.businessInfoHeight.maxHeight = "none !important";
            clearTimeout(d);
          }, 200);
        }).catch((ex) => {
          this.$vs.notify({
            title: "Error",
            text: "Unable to process your request at the moment.",
            color: "danger",
            iconPack: "feather",
            position: "top-right",
          });
        });
    },
    async goToTerms() {
      let data = {
        _id: this.id,
        info: { currentStep: "terms" },
      };

      await this.updateApplication(data).then((response) => {
        this.$router.push({
          name: "termsAndCondition",
          params: { introducerRequestedId: this.id },
        });
      }).catch((ex) => {
        this.$vs.notify({
          title: "Error",
          text: "Unable to process your request at the moment.",
          color: "danger",
          iconPack: "feather",
          position: "top-right",
        });
      });
    },

    goToEdit() {
      this.$router.push({
        name: "onboarding-form",
        params: { introducerRequestedId: this.id, card: "businessInfo" },
        query: { scrollTo:"beneficiaryOwnerArea" }
      });
    },

    goBack() {
      this.$router.push({
        name: "onboarding-form",
        params: { introducerRequestedId: this.id },
      });
    },
  },

  created() {
    this.id = this.$route.params.introducerRequestedId;
    this.userId = this.$route.params.introducerRequestedId;
  },

  mounted() {
    this.$vs.loading();
    const wait = setTimeout(() => {
      this.getDetail();
      clearTimeout(wait);
      this.$vs.loading.close();
    }, 4000);
  },
};
</script>

<style>
/* only applicable for this portal */
@media (max-width: 768px) {
 div .input-grp:nth-child(2n) {
    margin-top: 24px;
  }
}

</style>